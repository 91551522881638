<template>
    <!-- 项目遗留问题汇总 -->
    <div class="mod-config">
        <el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
            <div class="title">遗留问题汇总</div>
            <div class="line"></div>
            <el-row style="margin-bottom: 5px;">
                <el-col :span="pageSpan">
                    <el-button v-preventReClick class="btn" type="primary" size="small"
                        @click="downloadHandle()" :disabled="updateFlg || againFlg">导出</el-button>
                </el-col>
                <el-col :span="12">
                    <div style="float: right;margin-right: 15px;margin-top: 10px; vertical-align:middle;">
                        <span style="font-size: 16px; font-weight:bold;vertical-align: middle;color: rgb(25, 137, 228);">
                            巡检 {{ dataTotal.inspectionNum }}</span>
                        <span
                            style="font-size: 16px; font-weight:bold;vertical-align: middle; color: rgb(247, 168, 50);margin-left: 5px;">
                            报验 {{ dataTotal.testRecordNum }}</span>
                    </div>
                </el-col>
            </el-row>
            <div class="line"></div>
            <el-form ref="form" :model="form" inline label-width="80px" label-position="left">
                <el-row
                    style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height:40px;width:103%">
                    <el-col :span="16" style="margin-left:20px">
                        <el-form-item label="标的物" class="selFormItem">
                            <el-select class="selSubjectInput" v-model="subjectNo" placeholder="请选择" :disabled="updateFlg || againFlg" clearable>
                                <el-option v-for="item in subjectList" :key="item.displayValue"
                                    :label="item.displayName" :value="item.displayValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="专业" class="selFormItem">
                            <el-select class="selMajorInput" v-model="major" placeholder="请选择" :disabled="updateFlg || againFlg" clearable>
                                <el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
                                    :value="item.displayValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="关键字" class="selFormItem">
							<el-input class="selSearchInput" v-model="keyword" :disabled="updateFlg || againFlg" clearable></el-input>
						</el-form-item>
                    </el-col>
                    <el-col :span="7" class="selBtnGroup" style="margin-top:2px">
                        <el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()" :disabled="updateFlg || againFlg">重置</el-button>
                        <el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || againFlg"
                            @click="selGetDataList()">查询</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <div class="line" style="margin-top:-2px;margin-bottom:0px"></div>
            <el-row>
                <el-col :span="24" style="margin-top:-22px;margin-bottom:-2px">
                    <el-tabs v-model="activeIndex" type="card" @tab-click="handleTabClick" style="margin-left:-18px" >
                        <el-tab-pane :label="item.label" :key="item.name" v-for="item in tabs" :disabled="updateFlg || againFlg"
                            :name="item.name"></el-tab-pane>
                    </el-tabs>
                </el-col>
            </el-row>
        </el-card>
        <el-row>
            <div>
                <el-card v-show="activeIndex === 'patrolInspection'" style="margin-top: -15px; height: 505px;"
                    body-style="height:470px; padding: 0;">
                    <el-row style="height: 100%; margin-bottom: 10px;">
                        <el-col :span="24" style="height: 100%;">
                            <div class="box">
                                <el-table height="450" :row-class-name="tableRowClassName" ref="dataListTable"
                                    class="dataListTable" :data="inspectionList" header-align="center" border
                                    :cell-style="{ padding: '0px' }" header-cell-class-name="dataListTHeader"
                                    style="margin-bottom: 20px;">
                                    <el-table-column type="index" label="NO." width="60" align="center" fixed="left">
                                        <template slot-scope="scope">{{ (inspectionPageIndex2 - 1) * limit + scope.$index
                                            + 1
                                        }}</template>
                                    </el-table-column>
                                    <el-table-column prop="subjectName" label="标的物" align="center" width="100" fixed="left">
                                    </el-table-column>
                                    <el-table-column prop="majorName" label="专业" align="center" width="100" fixed="left">
                                    </el-table-column>
                                    <el-table-column prop="inspectionContent" label="巡视内容" min-width="150" header-align="center"
									show-overflow-tooltip align="left" fixed="left">
                                        <template slot-scope="scope">
                                            <span style="margin-left: 15px;" v-if="!getDisabled(scope.row.inspectionId)">{{
                                                scope.row.inspectionContent
                                            }}</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
                                                v-model="scope.row.inspectionContent"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sutContent" label="问题描述及整改意见" header-align="center" min-width="350"
                                        show-overflow-tooltip align="left" fixed="left">
                                        <template slot-scope="scope">
                                            <span style="margin-left: 15px;" v-if="!getDisabled(scope.row.inspectionId)">
                                                <el-button v-preventReClick type="text" size="small"
                                                    @click="lookHandle(scope.row.inspectionId)">{{ scope.row.sutContent
                                                    }}</el-button></span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
                                                v-model="scope.row.sutContent"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="提出时间" prop="inspectionTime" width="165" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.inspectionId)">
                                                {{ scope.row.inspectionTime | formatDate('yyyy-MM-dd') }}</span>
                                            <el-date-picker class="sp-input" v-model="scope.row.inspectionTime" type="date"
                                                value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px;"
                                                v-if="getDisabled(scope.row.inspectionId)">
                                            </el-date-picker>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="提出人" prop="inspectionMan" width="120" align="center">
                                        <template slot-scope="scope">
                                            <span style="margin-left: 15px;" v-if="!getDisabled(scope.row.inspectionId)">{{
                                                scope.row.inspectionMan
                                            }}</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
                                                v-model="scope.row.inspectionMan"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="closeTime" label="封闭时间" width="165" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.inspectionId)
                                                && scope.row.closeTime != ''">
                                                {{ scope.row.closeTime | formatDate('yyyy-MM-dd') }}</span>
                                            <span v-if="!getDisabled(scope.row.inspectionId)
                                                && scope.row.sutContent == '' && scope.row.closeTime == null">
                                                /</span>
                                            <el-date-picker class="sp-input" v-model="scope.row.closeTime" type="date"
                                                value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px;"
                                                v-if="getDisabled(scope.row.inspectionId)">
                                            </el-date-picker>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="closeMan" label="封闭人" width="130" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.inspectionId)">{{
                                                scope.row.closeMan
                                            }}</span>
                                            <span v-if="!getDisabled(scope.row.inspectionId)
                                                    && scope.row.sutContent == '' && scope.row.closeMan == ''">
                                                /</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
                                                v-model="scope.row.closeMan"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="closeOpinion" label="封闭意见说明" width="250" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.inspectionId)">{{
                                                scope.row.closeOpinion
                                            }}</span>
                                            <span v-if="!getDisabled(scope.row.inspectionId)
                                                    && scope.row.sutContent == '' && scope.row.closeOpinion == ''">
                                                /</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
                                                v-model="scope.row.closeOpinion"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="remark" label="备注" width="250" align="center" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.inspectionId)">{{
                                                scope.row.remark
                                            }}</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
                                                v-model="scope.row.remark"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="90" align="center" fixed="right">
                                        <template slot-scope="scope">
                                            <el-button v-preventReClick type="text" size="mini"
                                                @click="updateHandle(scope.row.inspectionId)"
                                                v-if="!updateFlg">修改</el-button>
                                            <!--修改时按钮-->
                                            <el-button v-preventReClick type="text" size="small"
                                                @click="updateInspection(scope.row)"
                                                v-if="getDisabled(scope.row.inspectionId)">保存</el-button>
                                            <el-button v-preventReClick type="text" size="small" @click="cancelUpdateHandle()"
                                                v-if="getDisabled(scope.row.inspectionId)">取消</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <el-pagination class="my-pagination" @size-change="sizeChangeHandle1" :disabled="updateFlg || againFlg"
                                @current-change="currentChangeHandle1" :current-page="inspectionPageIndex2"
                                :page-sizes="[10, 20, 50, 100]" :page-size="limit" :total="inspectionTotalPage"
                                layout="total, sizes, prev, pager, next, jumper">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
            <div>
                <el-card v-show="activeIndex === 'testRecord'" style="margin-top: -15px; height: 510px;"
                    body-style="height:470px; padding: 0;">
                    <el-row style="height: 100%; margin-bottom: 10px;">
                        <el-col :span="24" style="height: 100%;">
                            <div class="box">
                                <el-table height="450" :row-class-name="tableRowClassName" ref="dataListTable"
                                    class="dataListTable" :data="testRecordList" header-align="center" border
                                    :cell-style="{ padding: '0px' }" header-cell-class-name="dataListTHeader"
                                    style="margin-bottom: 20px;">
                                    <el-table-column prop="recSortCode" label="NO." width="60" align="center" fixed="left">
                                    </el-table-column>
                                    <el-table-column prop="subjectName" label="标的物" align="center" width="100" fixed="left">
                                    </el-table-column>
                                    <el-table-column prop="majorName" label="专业" align="center" width="100" fixed="left">
                                    </el-table-column>
                                   <el-table-column prop="probContext" label="检验项目" align="left" width="400" fixed="left"
									show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <span @click="lookInfoHandle(scope.row.testId)"
                                                v-if="!getDisabled(scope.row.testId)" style="color: #1f30ff;cursor:pointer;">{{
                                                    scope.row.probContext }}</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.testId)"
                                                v-model="scope.row.probContext" :disabled="againFlg"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sutContent" label="检验意见" width="350" align="left"
                                        show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.testId)">{{ scope.row.sutContent }}</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.testId)"
                                                v-model="scope.row.sutContent"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="testConclusionName" label="检验结论" align="center" width="120">
                                        <el-table-column label="1次检验" align="center" width="150px">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.testConclusion == 0 && !getDisabled(scope.row.testId)"
                                                    style="color: #507ce7;">{{
                                                        scope.row.testConclusionName }}</span>
                                                <span
                                                    v-if="(scope.row.testConclusion == 1 || scope.row.testConclusion == 3) && !getDisabled(scope.row.testId)"
                                                    style="color: #e7b243;">{{ scope.row.testConclusionName }}</span>
                                                <span v-if="scope.row.testConclusion == 2 && !getDisabled(scope.row.testId)"
                                                    style="color: #e70013;">{{
                                                        scope.row.testConclusionName }}</span>
                                                <el-select class="sp-input" v-model="scope.row.testConclusion" placeholder="请选择"
                                                    v-if="getDisabled(scope.row.testId)" style="width:125px" clearable
                                                    :disabled="!containCode(scope.row.recSortCode, ' ')">
                                                    <el-option v-for="item in ConclusionList" :key="item.displayValue"
                                                        :label="item.displayName" :value="item.displayValue">
                                                    </el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="2次检验" align="center" width="150px">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.testConclusion2 == 0 && !getDisabled(scope.row.testId)"
                                                    style="color: #507ce7;">{{
                                                        scope.row.testConclusionName2 }}</span>
                                                <span
                                                    v-if="(scope.row.testConclusion2 == 1 || scope.row.testConclusion2 == 3) && !getDisabled(scope.row.testId)"
                                                    style="color: #e7b243;">{{ scope.row.testConclusionName2 }}</span>
                                                <span v-if="scope.row.testConclusion2 == 2 && !getDisabled(scope.row.testId)"
                                                    style="color: #e70013;">{{
                                                        scope.row.testConclusionName2 }}</span>
                                                <el-select class="sp-input" v-model="scope.row.testConclusion2"
                                                    placeholder="请选择" v-if="getDisabled(scope.row.testId)" style="width:125px"
                                                    clearable :disabled="!containCode(scope.row.recSortCode, 'A')">
                                                    <el-option v-for="item in ConclusionList" :key="item.displayValue"
                                                        :label="item.displayName" :value="item.displayValue">
                                                    </el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="2次以上检验" align="center" width="150px">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.testConclusion3 == 0 && !getDisabled(scope.row.testId)"
                                                    style="color: #507ce7;">{{
                                                        scope.row.testConclusionName3 }}</span>
                                                <span
                                                    v-if="(scope.row.testConclusion3 == 1 || scope.row.testConclusion3 == 3) && !getDisabled(scope.row.testId)"
                                                    style="color: #e7b243;">{{ scope.row.testConclusionName3 }}</span>
                                                <span v-if="scope.row.testConclusion3 == 2 && !getDisabled(scope.row.testId)"
                                                    style="color: #e70013;">{{
                                                        scope.row.testConclusionName3 }}</span>
                                                <el-select class="sp-input" v-model="scope.row.testConclusion3"
                                                    placeholder="请选择" v-if="getDisabled(scope.row.testId)" style="width:125px"
                                                    clearable :disabled="!containCode(scope.row.recSortCode, 'B')">
                                                    <el-option v-for="item in ConclusionList" :key="item.displayValue"
                                                        :label="item.displayName" :value="item.displayValue">
                                                    </el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                    <el-table-column prop="testDate" label="检验日期 " width="165" align="center">
                                        <template slot-scope="scope">
                                            <span style="margin-left: 15px;" v-if="!getDisabled(scope.row.testId)">{{
                                                scope.row.testDate
                                            }}</span>
                                            <el-date-picker class="sp-input" v-model="scope.row.testDate" type="date"
                                                value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px;"
                                                v-if="getDisabled(scope.row.testId)">
                                            </el-date-picker>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="testMan" label="检验人" align="center" width="120">
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.testId)">{{ scope.row.testMan }}</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.testId)"
                                                v-model="scope.row.testMan"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="scope.row.closeTime" label="封闭日期" width="165" align="center">
                                        <template slot-scope="scope">
                                            <span style="margin-left: 15px;" v-if="!getDisabled(scope.row.testId)">
                                                {{ scope.row.closeTime | formatDate('yyyy-MM-dd') }}
                                            </span>
                                            <span style="margin-left: 15px;" v-if="!getDisabled(scope.row.testId)
                                                && scope.row.closeTime == null && scope.row.finalTestConclusion != '2'">
                                                /
                                            </span>
                                            <el-date-picker class="sp-input" v-model="scope.row.closeTime" type="date"
                                                value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px;"
                                                v-if="getDisabled(scope.row.testId)">
                                            </el-date-picker>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="closeMan" label="封闭人" align="center" width="150"
                                        show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.testId)">{{ scope.row.closeMan }}</span>
                                            <span v-if="!getDisabled(scope.row.testId)
                                                && scope.row.finalTestConclusion != '2' && (scope.row.closeMan == '' || scope.row.closeMan == null)">
                                                /</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.testId)"
                                                v-model="scope.row.closeMan"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="remark" label="备注" align="center" width="200" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.testId)">{{ scope.row.remark }}</span>
                                            <el-input class="sp-input" v-if="getDisabled(scope.row.testId)"
                                                v-model="scope.row.remark"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="90" align="left" fixed="right">
                                        <template slot-scope="scope">
                                            <el-button v-preventReClick type="text" size="mini"
                                                @click="updateHandle(scope.row.testId)"
                                                v-if="!updateFlg &&!againFlg">修改</el-button>
                                            <el-button v-preventReClick type="text" size="mini"
                                                @click="againHandle(scope.row.testId)"
                                                v-if="showAdd && scope.row.canRetest === '1' && !updateFlg && !againFlg">复检</el-button>
                                            <!--新增和复检时按钮-->
                                            <el-button v-preventReClick type="text" size="small" @click="saveTestRecord()"
                                                v-if="scope.row.testId == undefined && againFlg">保存</el-button>
                                            <el-button v-preventReClick type="text" size="small" @click="cancelSaveHandle()"
                                                v-if="scope.row.testId == undefined && againFlg">取消</el-button>
                                            <!--修改时按钮-->
                                            <el-button v-preventReClick type="text" size="small"
                                                @click="updateTestRecord(scope.row)"
                                                v-if="getDisabled(scope.row.testId) && !againFlg">保存</el-button>
                                            <el-button v-preventReClick type="text" size="small" @click="cancelUpdateHandle()"
                                                v-if="getDisabled(scope.row.testId) && !againFlg">取消</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <el-pagination class="my-pagination" @size-change="sizeChangeHandle2" :disabled="updateFlg || againFlg"
                                @current-change="currentChangeHandle2" :current-page="pageIndex"
                                :page-sizes="[10, 20, 50, 100]" :page-size="limit" :total="testRecordTotalPage"
                                layout="total, sizes, prev, pager, next, jumper">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </el-row>
    </div>
</template>
<script>
import $common from "@/utils/common.js"
export default {
    data() {
        return {
            showSearch: false,
            showDownload: false,
            showAdd: false,
            showEdit: false,
            showDelete: false,
            showCheck: false,
            showArchive: false,
            showRowDownload: false,
            pageSpan: 12,
            addOrUpdateVisible: false,
            form: {},
            subjectList: [],
            majorList: [],
            totalCount: 0,
            //当前页码
            pageIndex: 1,
            // 显示序号
            pageIndex2: 1,
            //当前记录数
            limit: 20,
            //总页数
            inspectionList: [],
            testRecordList: [],
            inspectionTotalPage: 0,
            inspectionPageIndex2: 1,
            testRecordTotalPage: 0,
            testRecordPageIndex2: 0,
            subjectNo: '',
            major: '',
            keyword: '',
            activeIndex: 'patrolInspection',
            tabs: [{ name: 'patrolInspection', label: '巡检' },
            { name: 'testRecord', label: '报验' }
            ],
            dataTotal: {
                inspectionNum: 0,
                testRecordNum: 0
            },
            recNos: [],
            updateFlg: false,
            addFlg: false,
            againFlg: false,
            ConclusionList: [],
        };
    },
    components: {
    },
    filters: {
        formatDate: function (value, args) {
            if (!value) {
                return ''
            }
            var dt = new Date(value);
            if (args == 'yyyy-M-d') {// yyyy-M-d
                let year = dt.getFullYear();
                let month = dt.getMonth() + 1;
                let date = dt.getDate();
                return `${year}-${month}-${date}`;
            } else if (args == 'yyyy-M-d H:m:s') {// yyyy-M-d H:m:s
                let year = dt.getFullYear();
                let month = dt.getMonth() + 1;
                let date = dt.getDate();
                let hour = dt.getHours();
                let minute = dt.getMinutes();
                let second = dt.getSeconds();
                return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
            } else if (args == 'yyyy-MM-dd') {// yyyy-MM-dd
                let year = dt.getFullYear();
                let month = (dt.getMonth() + 1).toString().padStart(2, '0');
                let date = dt.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${date}`;
            } else {// yyyy-MM-dd HH:mm:ss
                let year = dt.getFullYear();
                let month = (dt.getMonth() + 1).toString().padStart(2, '0');
                let date = dt.getDate().toString().padStart(2, '0');
                let hour = dt.getHours().toString().padStart(2, '0');
                let minute = dt.getMinutes().toString().padStart(2, '0');
                let second = dt.getSeconds().toString().padStart(2, '0');
                return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
            }
        }
    },
    mounted() {
        this.getDataList();
        this.getSubjectDropDown()
        this.getMajorList();
        this.getInspectionConclusion();
        this.pageIndex2 = 1;
    },
    methods: {
        getDisabled(val) {
			if (val == '' || val == undefined) {
				return true;
			} else {
				if (this.recNos.includes(val) && this.updateFlg) {
					return true;
				}
				return false;
			}
		},
        containCode(recSortCode, code) {
			var sortCode = recSortCode + "";
			var numReg = /^[0-9]+$/
			var numRe = new RegExp(numReg)
			if (numRe.test(sortCode) && code == ' ') {
				return true;
			} else if (sortCode.indexOf(code) != -1) {
				return true;
			} else {
				return false;
			}
		},
        updateHandle(val) {
			this.recNos.push(val)
			this.updateFlg = true;
		},
        updateInspection(val) {
			if (val.inspectionTime == null || val.inspectionTime == "") {
				this.$message.error("请填写提出时间");
				return;
			}

			if (val.inspectionMan == null || val.inspectionMan == "") {
				this.$message.error("请填写提出人");
				return;
			}

			let _url = "/business/inspectioninfo/update";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(val)
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.getDataList()
					this.updateFlg = false
					this.recNos = [];
				}
			});
		},
        cancelUpdateHandle() {
			this.getDataList()
		},
        //获取报验结论
		getInspectionConclusion() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getInspectionConclusion",
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.ConclusionList = data.body;
				}
			});
		},
        saveTestRecord() {
			if (this.dataList[0].probContext == null || this.dataList[0].probContext == "") {
				this.$message.error("请填写检验项目");
				return;
			}

			if (this.dataList[0].testDate == null || this.dataList[0].testDate == "") {
				this.$message.error("请填写检验时间");
				return;
			}

			if (this.dataList[0].testMan == null || this.dataList[0].testMan == "") {
				this.$message.error("请填写检验人");
				return;
			}

			if (this.dataList[0].testConclusion == '' && this.dataList[0].testConclusion2 == '' && this.dataList[0].testConclusion3 == '') {
				this.$message.error("请填写检验结论");
				return;
			}

			let _url = "/business/testrecord/saveOrUpdate";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(this.dataList[0])
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.getDataList()
					this.addFlg = false
					this.againFlg = false
				}
			});
		},
        updateTestRecord(val) {
			if (val.probContext == null || val.probContext == "") {
				this.$message.error("请填写检验项目");
				return;
			}

			if (val.testDate == null || val.testDate == "") {
				this.$message.error("请填写检验时间");
				return;
			}

			if (val.testMan == null || val.testMan == "") {
				this.$message.error("请填写检验人");
				return;
			}

			if (val.testConclusion == '' && val.testConclusion2 == '' && val.testConclusion3 == '') {
				this.$message.error("请填写检验结论");
				return;
			}

			let _url = "/business/testrecord/saveOrUpdate";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(val)
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.getDataList()
					this.updateFlg = false
					this.recNos = [];
				}
			});
		},
        //重置
        resetFormData() {
            this.subjectNo = '';
            this.major = '';
            this.keyword = '',
            this.testRecordPageIndex2 = 1;
            this.inspectionPageIndex2 = 1;
            this.getDataList();
        },
        downloadHandle() {
            const rLoading = this.openLoading();
            this.$http({
                url: this.$store.state.httpUrl + "/business/inspectioninfo/downloadProblemFile",
                method: 'get',
                params: this.$http.adornParams({
                    workNo: $common.getItem("workNo"),
                    subjectNo: this.subjectNo,
                    major: this.major,
                    stateFlg: "0",
                })
            }).then(({ data }) => {
                rLoading.close();
                if (data && data.resultCode === 200) {
                    if (this.$store.state.showTestFlag) {
                        window.open(data.body)
                    } else {
                        this.global.downLoadFile(data.body, '项目遗留问题汇总表.xlsx');
                    }
                } else {
                }
            })
        },
        getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					// if (this.subjectList != undefined && this.subjectList.length > 0) {
					// 	this.subjectNo = this.subjectList[0].displayValue
					// 	if (this.showSearch) {
					// 		this.getDataList();
					// 	}
					// }
				}
			});
		},
        getMajorList() {
            this.$http({
                url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.majorList = data.body;
                }
            });
        },
        getDataList() {
            // 巡检
            this.$http({
                url: this.$store.state.httpUrl + "/business/inspectioninfo/list",
                method: 'get',
                params: this.$http.adornParams({
                    limit: this.limit,
                    page: this.inspectionPageIndex2,
                    workNo: $common.getItem("workNo"),
                    subjectNo: this.subjectNo,
                    major: this.major,
                    sutContent: this.keyword,
                    closeState: "0",
                })
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.inspectionList = data.body.records;
                    this.inspectionTotalPage = data.body.totalCount;
                    this.dataTotal.inspectionNum = data.body.totalCount;
                } else {
                    this.inspectionList = []
                    this.inspectionTotalPage = 0
                    this.inspectionPageIndex2 = 1
                }
            })

            // 报验
            this.$http({
                url: this.$store.state.httpUrl + "/business/testrecord/list",
                method: 'get',
                params: this.$http.adornParams({
                    page: this.testRecordPageIndex2,
                    major: this.major,
                    subjectNo: this.subjectNo,
                    closeState: "0",
                    limit: this.limit,
                    probContext: this.keyword,
                    workNo: $common.getItem("workNo"),
                })
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.testRecordList = data.body.records;
                    this.testRecordTotalPage = data.body.totalCount;
                    this.dataTotal.testRecordNum = data.body.totalCount;
                } else {
                    this.testRecordList = []
                    this.testRecordTotalPage = 0
                    this.testRecordTotalPage = 1
                }
            })
            this.updateFlg = false;
            this.recNos = []
        },
        selGetDataList() {
            this.pageIndex = 1;
            this.getDataList();
        },
        handleTabClick(tab) {
            this.activeIndex = tab.name
        },
        // 每页数
        sizeChangeHandle1(val) {
            this.limit = val
            this.inspectionPageIndex2 = 1
            this.getDataList()
        },
        sizeChangeHandle2(val) {
            this.limit = val
            this.testRecordPageIndex2 = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle1(val) {
            this.inspectionPageIndex2 = val
            this.getDataList()
        },
        currentChangeHandle2(val) {
            this.testRecordPageIndex2 = val
            this.getDataList()
        },
        siteContentViewHeight() {
            var height = this.$store.state.common.documentClientHeight - 160;
            return { height: height + 'px' }
        },
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id);
            })
        },
        presetHandle() {
            this.userPresetVisible = true;
            this.$nextTick(() => {
                this.$refs.userPreset.init();
            })
        },
        tableRowClassName({ row, rowIndex }) {
            return $common.tableRowClassName({ row, rowIndex });
        },
    }
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
    font-size: 14px;
    color: #1c1c1c;
    display: inline-block;
    width: 80px;
}

.my-pagination {
    margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
    // background-color: #d3e2f4 !important;
    font-size: 15px;
    padding: 2px 0;
}

::v-deep .el-tabs__item.is-active {
    color: #1d1d86;
    background-color: #daeafc;
}

::v-deep .el-tabs__item:hover {
    color: #121213;
    background-color: #eceeed;
}

::v-deep .el-tabs--top .el-tabs__item.is-top {
    width: 134.5px;
    text-align: center;
    height: 40px;
}

::v-deep(.selSubjectInput .el-input__inner) {
    width: 120px !important;
    height: 30px !important;
}

::v-deep(.selMajorInput .el-input__inner) {
    width: 110px !important;
    height: 30px !important;
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 180px !important;
	height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
    font-size: smaller;
}

::v-deep(.el-table .el-table__body-wrapper) {
	z-index: 2
}

::v-deep(.sp-input .el-input__inner) {
	width: 100% !important;
}
</style>
